import { ActionIcon, Center, Drawer, Group, Stack, useMantineTheme } from "@mantine/core"
import { useDisclosure, useWindowScroll } from "@mantine/hooks"
import { motion } from "framer-motion"
import { TbGridDots } from "react-icons/tb"
import Brand from "./Brand"
import { NavLink } from "./NavLink"


export default function HeaderBase({ leftSection, rightSection, showCTA = true, slim = false }) {

    const theme = useMantineTheme()
    const [scroll] = useWindowScroll()

    // Mobile drawer
    const [drawerOpened, drawerHandlers] = useDisclosure()

    const brandComponent = (
        <Brand {...(slim && {
            variant: "gray",
            size: "1.5rem",
        })} />
    )

    const ctaComponent = (
        <Group noWrap>
            {showCTA &&
                <NavLink href="/?waitlist" button size={slim ? "xs" : "md"}>
                    Join the Waitlist
                </NavLink>}
        </Group>
    )

    return (
        <Center
            pos="sticky" top={0} className="z-50"
            p="0.5rem" bg="white"
            component={motion.header} variants={headerAnimations(theme)} initial="top" animate={scroll.y > 0 ? "scrolled" : "top"}
        >
            <Group maw={theme.other.contentWidth} position="apart" className="flex-1">

                <div className="hidden md:block">
                    <Group spacing="2.5rem">
                        {brandComponent}
                        {leftSection}
                    </Group>
                </div>

                <div className="md:hidden pl-sm">
                    {brandComponent}
                </div>

                <div className="hidden md:block">
                    <Group spacing="2rem">
                        {rightSection}
                        {ctaComponent}
                    </Group>
                </div>

                <div className="md:hidden">
                    <ActionIcon size="xl" onClick={drawerHandlers.open}>
                        <TbGridDots size={theme.fontSizes.xl} />
                    </ActionIcon>

                    <Drawer
                        size="xs" position="right" closeButtonProps={{ size: "lg" }}
                        opened={drawerOpened} onClose={drawerHandlers.close}
                        className="md:hidden"
                    >
                        <Stack spacing="lg">
                            <Center py="xl">
                                {ctaComponent}
                            </Center>
                            {leftSection}
                            {rightSection}
                        </Stack>
                    </Drawer>
                </div>
            </Group>
        </Center>
    )
}

const headerAnimations = theme => ({
    top: {
        boxShadow: "none",
    },
    scrolled: {
        boxShadow: theme.shadows.xs,
    },
})

